@use '@reverbdotcom/cadence/styles/tools/mixins/rc-button';
.quick-add-card {
  border: solid 0.1rem var(--rc-color-border-primary);
  padding: 2rem;
  border-radius: $input-border-radius;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .d-flex & {
    height: 100%;
  }
}

.quick-add-card__image {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 2rem;
}

.quick-add-card__title {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0 0 1rem;
}

.quick-add-card__date {
  color: var(--rc-color-text-secondary);
  font-size: 1.6rem;
  margin: 0 0 2rem;
}

.quick-add-card__button {
  @include rc-button.filled;
  width: 100%;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: normal;
}

.quick-add-card__added-text {
  margin: auto 0 0;
  // these are all to get the height to be the same as
  // the buttons to prevent UI jumping
  line-height: 1.4;
  padding: .375em 0;
  border: solid 0.1rem transparent;
}
